import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import { getAddress } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'
import { useAccount, useContract, useNetwork, useProvider } from 'wagmi'
import { Airdrop } from '../components/Airdrop'
import { BURN_ROLE, DEFAULT_ADMIN_ROLE, MINT_ROLE, MUMBAI_CHAIN_ID, NTX_CONFIG, POLYGON_CHAIN_ID } from '../const'
import { useExplorerPrefix } from '../hooks/useExplorerPrefix'

export default function NTxAirdropPage() {
  const { isConnected, address } = useAccount()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isMinter, setIsMinter] = useState(false)
  const [isBurner, setIsBurner] = useState(false)
  const [isMined, setIsMined] = useState(0)
  const explorerPrefix = useExplorerPrefix()
  const { chain } = useNetwork()

  let config: any
  if (chain?.id === POLYGON_CHAIN_ID) {
    config = NTX_CONFIG.polygon
  } else if (chain?.id === MUMBAI_CHAIN_ID) {
    config = NTX_CONFIG.mumbai
  } else {
    config = NTX_CONFIG.hardhat
  }

  const provider = useProvider()
  const contract = useContract({
    addressOrName: config.addressOrName,
    contractInterface: config.contractInterface,
    signerOrProvider: provider
  })

  useEffect(() => {
    if (!address) return

    const setRoles = async () => {
      const isAdmin = await contract?.hasRole(DEFAULT_ADMIN_ROLE, address)
      setIsAdmin(isAdmin)
      const isMinter = await contract?.hasRole(MINT_ROLE, address)
      setIsMinter(isMinter)
      const isBurner = await contract?.hasRole(BURN_ROLE, address)
      setIsBurner(isBurner)
    }

    if (address) {
      setRoles().catch((e) => console.error(e))
    }
  }, [address, contract])

  return isConnected ? (
    <div>
      <>
        <Divider textAlign="left" sx={{ marginTop: '20px', marginBottom: '20px' }}>
          <Chip label="Information" />
        </Divider>
        <div>current network: {chain?.name}</div>
        <div>
          <span>NTx CONTRACT:</span>
          <a href={explorerPrefix + 'address/' + contract.address} target="_blank" rel="noopener noreferrer">
            {getAddress(contract.address)}
          </a>
        </div>
        <div>
          <span>YOUR ADDRESS:</span>
          <a href={explorerPrefix + 'address/' + address} target="_blank" rel="noopener noreferrer">
            {address && getAddress(address)}
          </a>
        </div>
        {(isAdmin || isMinter || isBurner) && (
          <Divider textAlign="left" sx={{ marginTop: '60px', marginBottom: '20px' }}>
            <Chip label="Roles" />
          </Divider>
        )}
        {isAdmin && <div>You're an ADMIN 👨‍💼</div>}
        {isMinter && <div>You're a MINTER 🔨</div>}
        {isBurner && <div>You're a BURNER 🔥</div>}
      </>
      <div>
        <Divider textAlign="left" sx={{ marginTop: '60px', marginBottom: '20px' }}>
          <Chip label="Airdrop" />
        </Divider>
        <Airdrop contract={contract} setIsMined={setIsMined} isMinter={isMinter} />
      </div>
    </div>
  ) : (
    <div>Please Connect Wallet</div>
  )
}
