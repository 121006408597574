import { createTheme, ThemeProvider } from '@mui/material/styles'
import { defaultTheme, Layout, LayoutProps } from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'

const theme = createTheme({
  ...defaultTheme,
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})

export default (props: LayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Layout {...props} appBar={AppBar} menu={Menu} />
    </ThemeProvider>
  )
}
