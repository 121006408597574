import { gql, request } from 'graphql-request'
import { useState } from 'react'
import { useAccount, useContract, useProvider, useSigner } from 'wagmi'
import { useExplorerPrefix } from '../hooks/useExplorerPrefix'
import { useSubgraphUrl } from '../hooks/useSubgraphUrl'
import ntxABI from '../ntx.abi.json'

const GET_USER_QUERY = gql`
  query getUser($id: String!) {
    user(id: $id) {
      id
      amount
      mints(orderBy: timestamp, orderDirection: desc) {
        transaction {
          id
        }
        amount
        timestamp
      }
      burns(orderBy: timestamp, orderDirection: desc) {
        transaction {
          id
        }
        amount
        timestamp
      }
    }
  }
`

const User = (props: any) => {
  const user: any = props.user
  const refreshUser: any = props.refreshUser
  const explorerPrefix = useExplorerPrefix()

  // const [mintAmount, setMintAmount] = useState('')
  const [burnAmount, setBurnAmount] = useState('')

  const totalMints =
    user.mints.length > 0 ? user.mints.reduce((acc: any, mint: any) => acc + parseInt(mint.amount), 0) : 0
  const totalBurns =
    user.mints.length > 0 ? user.burns.reduce((acc: any, burn: any) => acc + parseInt(burn.amount), 0) : 0
  const amount = totalMints - totalBurns

  const { data: signer } = useSigner()
  const provider = useProvider()

  const contractAddressByChainId = (chainId: any) => {
    if (chainId === 137) {
      return '0x8Fc9585896682c855F502335A5f39D584FfC4386' // polygon
    } else if (chainId === 80001) {
      return '0x1C07cC58fca66fa21a0f5c7217Ef83234Ada02d3' // mumbai
    } else {
      return '0x5FbDB2315678afecb367f032d93F642f64180aa3' // hardhat
    }
  }

  const NTx = useContract({
    addressOrName: contractAddressByChainId(provider._network.chainId),
    contractInterface: ntxABI,
    signerOrProvider: provider
  })

  const burn = async () => {
    let amount: number
    try {
      amount = parseInt(burnAmount)
    } catch (err: any) {
      return
    }

    const estimatedGas = (await NTx.connect(signer)?.estimateGas.burn(user.id, 0, amount)).toNumber()
    const tx = await NTx.connect(signer)?.burn(user.id, 0, amount, {
      gasLimit: Math.round(estimatedGas * 1.2)
    })
    await tx.wait()
    alert('success')

    refreshUser(user.id)
  }

  return (
    <div style={{ marginTop: '40px' }}>
      <hr />
      <div>account: {user.id}</div>
      <hr />
      <div>amount: {amount}</div>
      <hr />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }}>
          <div style={{ marginBottom: '8px' }}>mints:</div>
          {user.mints.map((mint: any, index: number) => {
            return (
              <div key={index}>
                <a href={explorerPrefix + 'tx/' + mint.transaction.id} target="_blank" rel="noopener noreferrer">
                  ➜ {mint.amount} ({mint.timestamp})
                </a>
              </div>
            )
          })}
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ marginBottom: '8px' }}>burns:</div>
          {user.burns.map((burn: any, index: number) => {
            return (
              <div key={index}>
                <a href={explorerPrefix + 'tx/' + burn.transaction.id} target="_blank" rel="noopener noreferrer">
                  ➜ {burn.amount} ({burn.timestamp})
                </a>
              </div>
            )
          })}
        </div>
      </div>
      <hr />
      <div>
        {/* <div>
          <label>
            Mint amount:
            <input
              style={{ width: '100px', marginLeft: '4px' }}
              type="text"
              value={mintAmount}
              onChange={(e) => setMintAmount(e.target.value)}
            />
          </label>
          <button style={{ marginLeft: '4px' }} onClick={() => mint()}>
            run
          </button>
        </div> */}
        <div>
          <label>
            Burn amount:
            <input
              style={{ width: '100px', marginLeft: '4px' }}
              type="text"
              value={burnAmount}
              onChange={(e) => setBurnAmount(e.target.value)}
            />
          </label>
          <button style={{ marginLeft: '4px' }} onClick={() => burn()}>
            run
          </button>
        </div>
      </div>
    </div>
  )
}

export default function NTxBurnPage() {
  const { isConnected } = useAccount()
  const [address, setAddress] = useState('')
  const [user, setUser] = useState<any>()
  const subgraphUrl = useSubgraphUrl()

  const getUser = async (address: string) => {
    const data = await request(subgraphUrl, GET_USER_QUERY, {
      id: address.toLowerCase()
    })

    console.log(data.user)
    if (!data.user) alert('유저 정보가 없습니다.')
    setUser(data.user)
  }

  return isConnected ? (
    <div>
      <label>
        Enter address:
        <input
          style={{ width: '500px', marginLeft: '4px' }}
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </label>
      <button style={{ marginLeft: '4px' }} onClick={() => getUser(address)}>
        find
      </button>

      {user && <User user={user} refreshUser={getUser} />}
    </div>
  ) : (
    <div>Please Connect Wallet</div>
  )
}
