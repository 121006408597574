import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { API_URL } from 'const'
import { useState } from 'react'

const InviteCodeUnregisterPage = () => {
  const [code, setCode] = useState('')
  const [isErrorCode, setIsErrorCode] = useState<null | boolean>(null)

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }

  const createInviteCode = async () => {
    const body = { code: code }
    const res = await fetch(`${API_URL}/api/invitecodes/unregister`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;chaset=utf-8' },
      body: JSON.stringify(body),
      credentials: 'include'
    })
    const result = await res.json()
    if (result) {
      return setIsErrorCode(false)
    }
    setIsErrorCode(true)
  }

  // isErrorCode가 true라면 잘못된 코드입니다. 표출 / false라면 정상적으로 처리되었습니다. 표출

  return (
    <div>
      <TextField
        onChange={handleCodeChange}
        error={isErrorCode as boolean}
        id="code"
        label="Code"
        defaultValue=""
        helperText={
          isErrorCode === false ? '정상적으로 처리되었습니다.' : isErrorCode === null ? '' : '잘못된 코드입니다.'
        }
        sx={{ width: 500 }}
      />
      <Button
        variant="contained"
        onClick={createInviteCode}
        style={{ marginLeft: 15, marginTop: 13 }}
        disabled={isErrorCode || code.length === 0}
      >
        Unregister Invite Code
      </Button>
    </div>
  )
}

export default InviteCodeUnregisterPage
