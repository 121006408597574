import styled from '@emotion/styled'
import { Login } from 'react-admin'
import { ADMIN_URL, API_URL } from '../const'

const Button = styled.a`
  background-color: #1988f9;
  font-size: 16px;
  padding: 10px 19px;
  cursor: pointer;
  margin: 0rem 1rem;
  text-align: center;

  text-decoration: none;
  color: #fff;
  display: block;
`

const LoginPage = (props: any) => (
  <Login {...props} sx={{ '.MuiPaper-root': { height: '200px' } }}>
    <Button href={`${API_URL}/api/dev/login/federated/google?redirect=${ADMIN_URL}`}>Google Login</Button>
    <br />
    <Button href={'https://mail.google.com/mail/logout?hl=en'}>Google Logout</Button>
  </Login>
)

export default LoginPage
