import Box from '@mui/material/Box'
import { useState } from 'react'

import { MenuItemLink, MenuProps, useSidebarState, useTranslate } from 'react-admin'

// import categories from '../categories'
// import invoices from '../invoices'
// import orders from '../orders'
// import products from '../products'
// import reviews from '../reviews'
//import visitors from '../visitors'
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket'
import ChatIcon from '@mui/icons-material/Chat'
import SubMenu from './SubMenu'

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers'

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true
  })
  const translate = useTranslate()
  const [open] = useSidebarState()

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <Box
      sx={{
        width: open ? 300 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
      }}
    >
      {/*<DashboardMenuItem />*/}
      <SubMenu
        handleToggle={() => handleToggle('menuSales')}
        isOpen={state.menuSales}
        name="NTx"
        icon={<AirplaneTicketIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/ntx-airdrop"
          state={{ _scrollToTop: true }}
          primaryText={'Airdrop'}
          leftIcon={<AirplaneTicketIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/ntx-burn"
          state={{ _scrollToTop: true }}
          primaryText={'Burn'}
          leftIcon={<AirplaneTicketIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/ntx-account"
          state={{ _scrollToTop: true }}
          primaryText={'Account'}
          leftIcon={<AirplaneTicketIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/ntx-event"
          state={{ _scrollToTop: true }}
          primaryText={'Event'}
          leftIcon={<AirplaneTicketIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/ntx-mintlist"
          state={{ _scrollToTop: true }}
          primaryText={'Mintlist'}
          leftIcon={<AirplaneTicketIcon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuSales')}
        isOpen={state.menuSales}
        name="Holder Test"
        icon={<ChatIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/invite-codes"
          state={{ _scrollToTop: true }}
          primaryText={'Invite Codes'}
          leftIcon={<ChatIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/invite-code-create"
          state={{ _scrollToTop: true }}
          primaryText={'Invite Code Create'}
          leftIcon={<ChatIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/invite-code-unregister"
          state={{ _scrollToTop: true }}
          primaryText={'Invite Code Unregister'}
          leftIcon={<ChatIcon />}
          dense={dense}
        />
      </SubMenu>
      {/*<SubMenu*/}
      {/*  handleToggle={() => handleToggle('menuCatalog')}*/}
      {/*  isOpen={state.menuCatalog}*/}
      {/*  name="pos.menu.catalog"*/}
      {/*  icon={<ProductIcon />}*/}
      {/*  dense={dense}*/}
      {/*>*/}
      {/*  <MenuItemLink*/}
      {/*    to="/products"*/}
      {/*    state={{ _scrollToTop: true }}*/}
      {/*    primaryText={translate(`resources.products.name`, {*/}
      {/*      smart_count: 2*/}
      {/*    })}*/}
      {/*    leftIcon={<ProductIcon />}*/}
      {/*    dense={dense}*/}
      {/*  />*/}
      {/*  <MenuItemLink*/}
      {/*    to="/categories"*/}
      {/*    state={{ _scrollToTop: true }}*/}
      {/*    primaryText={translate(`resources.categories.name`, {*/}
      {/*      smart_count: 2*/}
      {/*    })}*/}
      {/*    leftIcon={<CategoryIcon />}*/}
      {/*    dense={dense}*/}
      {/*  />*/}
      {/*</SubMenu>*/}
      {/*<SubMenu*/}
      {/*  handleToggle={() => handleToggle('menuCustomers')}*/}
      {/*  isOpen={state.menuCustomers}*/}
      {/*  name="pos.menu.customers"*/}
      {/*  icon={<VisitorIcon />}*/}
      {/*  dense={dense}*/}
      {/*>*/}
      {/*  <MenuItemLink*/}
      {/*    to="/customers"*/}
      {/*    state={{ _scrollToTop: true }}*/}
      {/*    primaryText={translate(`resources.customers.name`, {*/}
      {/*      smart_count: 2*/}
      {/*    })}*/}
      {/*    leftIcon={<VisitorIcon />}*/}
      {/*    dense={dense}*/}
      {/*  />*/}
      {/*  <MenuItemLink*/}
      {/*    to="/segments"*/}
      {/*    state={{ _scrollToTop: true }}*/}
      {/*    primaryText={translate(`resources.segments.name`, {*/}
      {/*      smart_count: 2*/}
      {/*    })}*/}
      {/*    leftIcon={<LabelIcon />}*/}
      {/*    dense={dense}*/}
      {/*  />*/}
      {/*</SubMenu>*/}
      {/*<MenuItemLink*/}
      {/*  to="/reviews"*/}
      {/*  state={{ _scrollToTop: true }}*/}
      {/*  primaryText={translate(`resources.reviews.name`, {*/}
      {/*    smart_count: 2*/}
      {/*  })}*/}
      {/*  leftIcon={<ReviewIcon />}*/}
      {/*  dense={dense}*/}
      {/*/>*/}
    </Box>
  )
}

export default Menu
