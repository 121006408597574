import { Button as Btn } from '@mui/material'
import { styled as MuiStyled } from '@mui/material/styles'
import { ethers } from 'ethers'
import { useAccount, useConnect, useDisconnect, useNetwork } from 'wagmi'

const Button = MuiStyled(Btn)({
  marginInline: '0.5em'
})

export function WalletLogin() {
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
  const { isConnected } = useAccount()
  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()

  const polygon = {
    chainId: ethers.utils.hexValue(137), // '0x89'
    chainName: 'Polygon Mainnet',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com']
  }

  const mumbai = {
    chainId: ethers.utils.hexValue(80001), // '0x13881'
    chainName: 'Mumbai',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com']
  }

  const addPolygonChain = async () => {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [polygon]
    })
  }

  const addMumbaiChain = async () => {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [mumbai]
    })
  }

  const switchToPolygonChain = async () => {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId: polygon.chainId
        }
      ]
    })
  }

  const switchToMumbaiChain = async () => {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId: mumbai.chainId
        }
      ]
    })
  }

  return (
    <>
      {isConnected ? (
        <Button variant="contained" onClick={() => disconnect()}>
          Disconnect
        </Button>
      ) : (
        <>
          {connectors.map((connector) => (
            <Button
              variant="contained"
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => connect({ connector })}
            >
              {connector.name}
              {!connector.ready && ' (unsupported)'}
              {isLoading && connector.id === pendingConnector?.id && ' (connecting)'}
            </Button>
          ))}
        </>
      )}

      <Button variant="contained" onClick={addPolygonChain} disabled={chain?.network === 'matic'}>
        Add Polygon Network
      </Button>
      <Button variant="contained" onClick={switchToPolygonChain} disabled={chain?.network === 'matic'}>
        Switch to Polygon Network
      </Button>
      <Button variant="contained" onClick={addMumbaiChain} disabled={chain?.network === 'maticmum'}>
        Add Mumbai Network
      </Button>
      <Button variant="contained" onClick={switchToMumbaiChain} disabled={chain?.network === 'maticmum'}>
        Switch to Mumbai Network
      </Button>
    </>
  )
}
