import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import { API_URL } from 'const'
import { ethers } from 'ethers'
import { useState } from 'react'

const InviteCodeCreatePage = () => {
  const [inviteCodes, setInviteCodes] = useState<{ code: string }[]>([])
  const [address, setAddress] = useState('')
  const [isErrorAddress, setIsErrorAddress] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [count, setCount] = useState(1)

  // 관리자 주소인지 확인
  const isAdminAdress = address === '0x0000000000000000000000000000000000000000'

  const countChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(Number(e.target.value))
  }

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!ethers.utils.isAddress(e.target.value)) {
      setIsErrorAddress(true)
      setErrorMessage('Invalid address')
    } else {
      setIsErrorAddress(false)
      setErrorMessage('')
    }

    setAddress(e.target.value)
  }

  const handleCopyClipBoard = async () => {
    const data = inviteCodes.map(({ code }) => code).join('\n')
    try {
      await navigator.clipboard.writeText(data)
      alert('copy success')
    } catch (error) {
      alert('copy fail')
    }
  }

  const createInviteCode = async () => {
    const body = { address: address, count: count }
    const res = await fetch(`${API_URL}/api/invitecodes/admin/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;chaset=utf-8' },
      body: JSON.stringify(body),
      credentials: 'include'
    })
    const codes = await res.json()
    setInviteCodes(codes)
  }

  return (
    <div>
      <TextField
        onChange={handleAddressChange}
        error={isErrorAddress}
        id="address"
        label="Address"
        defaultValue=""
        helperText={errorMessage}
        sx={{ width: 500 }}
      />
      <TextField
        onChange={countChange}
        id="count"
        label="count"
        defaultValue={isAdminAdress ? count : 1}
        disabled={!isAdminAdress}
        sx={{ width: 100, marginLeft: '20px' }}
      />
      <Button
        variant="contained"
        onClick={createInviteCode}
        style={{ marginLeft: 15, marginTop: 13 }}
        disabled={isErrorAddress || address.length === 0}
      >
        Create Invite Code
      </Button>
      {inviteCodes.length > 0 && (
        <Button onClick={handleCopyClipBoard} variant="contained" style={{ marginLeft: 15, marginTop: 13 }}>
          Copy
        </Button>
      )}
      {inviteCodes.length > 0 && (
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {inviteCodes.map(({ code }) => (
                <TableRow key={code} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{code}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default InviteCodeCreatePage
