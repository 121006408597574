import { Button } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { InviteCode, useAdminInviteCodesList } from 'hooks/query/useAdminInviteCodesList'
import moment from 'moment'
import * as XLSX from 'xlsx'

const InviteCodesPage = () => {
  const { data: inviteCodes, isLoading, isError } = useAdminInviteCodesList()

  const _download = (data: InviteCode[], type?: string) => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()

    worksheet['!cols'] = [{ wch: 25 }, { wch: 48 }, { wch: 6 }, { wch: 46 }, { wch: 24 }, { wch: 8 }]

    XLSX.utils.book_append_sheet(workbook, worksheet, '1')

    if (type === 'used') {
      return XLSX.writeFile(workbook, 'invite_code_used.xlsx')
    }

    if (type === 'unused') {
      return XLSX.writeFile(workbook, 'invite_code_unused.xlsx')
    }

    XLSX.writeFile(workbook, 'invite_code.xlsx')
  }

  const downloadAll = () => {
    _download(inviteCodes as InviteCode[])
  }

  const downloadUsed = () => {
    _download(inviteCodes?.filter((inviteCode) => inviteCode.used) as InviteCode[], 'used')
  }

  const downloadUnused = () => {
    _download(inviteCodes?.filter((inviteCode) => !inviteCode.used) as InviteCode[], 'unused')
  }

  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error.</div>

  return (
    <>
      <div
        style={{
          display: 'flex',
          margin: '16px 0px',
          gap: '16px'
        }}
      >
        <Button variant="contained" onClick={downloadAll}>
          EXPORT(xlsx) - invitecodes (all)
        </Button>
        <Button variant="contained" onClick={downloadUsed}>
          EXPORT(xlsx) - invitecodes (used)
        </Button>
        <Button variant="contained" onClick={downloadUnused}>
          EXPORT(xlsx) - invitecodes (unused)
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">CODE</TableCell>
              <TableCell align="center">USED</TableCell>
              <TableCell align="center">OWNER</TableCell>
              <TableCell align="center">CREATEAT</TableCell>
              <TableCell align="center">isHolder</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inviteCodes &&
              inviteCodes.map(({ code, used, user, owner, createat, isHolder }) => (
                <TableRow key={code} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center" sx={{ textDecoration: used ? 'line-through' : '' }}>
                    {code}
                  </TableCell>
                  <TableCell align="center">{user}</TableCell>
                  <TableCell align="center">{owner}</TableCell>
                  <TableCell align="center">{moment(createat).format('YYYY-MM-DD HH:MM:DD')} UTC</TableCell>
                  <TableCell align="center">{String(isHolder)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default InviteCodesPage
