import { useEffect, useState } from 'react'
import { useNetwork } from 'wagmi'

import { MUMBAI_CHAIN_ID, POLYGON_CHAIN_ID } from '../const'

export function useSubgraphUrl(): string {
  const [subgraphUrl, setSubgraphUrl] = useState('')

  const { chain } = useNetwork()

  useEffect(() => {
    const chainId = chain?.id

    if (chainId) {
      if (chainId === POLYGON_CHAIN_ID) {
        setSubgraphUrl('https://api.thegraph.com/subgraphs/name/0xociety/xociety-ntx')
      } else if (chainId === MUMBAI_CHAIN_ID) {
        setSubgraphUrl('https://api.thegraph.com/subgraphs/name/0xociety/xociety-ntx-mumbai')
      } else {
        setSubgraphUrl('http://localhost:8000/subgraphs/name/NTx-subgraph')
      }
    }
  }, [chain])

  return subgraphUrl
}
