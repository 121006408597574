import { API_URL } from './const'

const authProvider = {
  login: () => {
    return Promise.resolve()
  },
  logout: () => {
    return fetch(`${API_URL}/api/dev/logout`, { credentials: 'include' })
      .then((res: any) => {})
      .catch((e: any) => {})
  },
  checkError: () => Promise.resolve(),
  checkAuth: async () => {
    const res = await fetch(`${API_URL}/api/dev/session`, { credentials: 'include' })
    const data = await res.json()
    return data.isAuthenticated ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => Promise.reject('Unknown method'),
  getIdentity: async () => {
    const res = await fetch(`${API_URL}/api/dev/user`, { credentials: 'include' })
    const data = await res.json()
    return Promise.resolve({
      id: data.id,
      fullName: data.name,
      avatar: data.picture
    })
  }
}

export default authProvider
