import { Pagination } from '@mui/material'
import { API_URL } from 'const'
import { useEffect, useState } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import * as XLSX from 'xlsx'
import usePagination from '../hooks/usePagination'

export default function NTxMintlistPage() {
  const { isConnected } = useAccount()
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const { chain } = useNetwork()
  const PER_PAGE = 500

  const count = Math.ceil(data.length / PER_PAGE)
  const _DATA = usePagination(data, PER_PAGE)

  useEffect(() => {
    fetch(`${API_URL}/api/mintlist/admin/list?network=${chain?.network}`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((err: any) => console.log(err.message))
  }, [])

  const handleChange = (e: any, p: any) => {
    setPage(p)
    _DATA.jump(p)
  }

  const _download = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()

    worksheet['!cols'] = [{ wch: 5 }, { wch: 50 }, { wch: 10 }, { wch: 10 }, { wch: 25 }]

    XLSX.utils.book_append_sheet(workbook, worksheet, '1')
    XLSX.writeFile(workbook, 'mintlist.xlsx')
  }

  const downloadThisPage = () => {
    if (data.length === 0) return alert('export할 데이터가 없습니다.')

    _download(_DATA.currentData())
  }

  const downloadAll = () => {
    if (data.length === 0) return alert('export할 데이터가 없습니다.')

    _download(data)
  }

  return isConnected ? (
    <div>
      <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />

      <div>
        <button
          style={{ marginTop: '20px', marginRight: '10px' }}
          onClick={() => {
            downloadThisPage()
          }}
        >
          EXPORT(xlsx) - events (only this page)
        </button>
        <button
          style={{ marginTop: '20px' }}
          onClick={() => {
            downloadAll()
          }}
        >
          EXPORT(xlsx) - events (all)
        </button>
      </div>

      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
        <div style={{ width: '450px' }}>address</div>
        <div style={{ width: '50px' }}>grade</div>
        <div style={{ width: '50px' }}>round</div>
        <div style={{ width: '250px' }}>createdAt</div>
      </div>
      <hr />
      <div>
        {_DATA &&
          _DATA.currentData().map((data: any, index: number) => {
            return (
              <div key={index} style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
                <div style={{ width: '450px' }}>{data.address}</div>
                <div style={{ width: '50px' }}>{data.grade}</div>
                <div style={{ width: '50px' }}>{data.round}</div>
                <div style={{ width: '250px' }}>{data.createdAt}</div>
              </div>
            )
          })}
      </div>
    </div>
  ) : (
    <div>Please Connect Wallet</div>
  )
}
