import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { isAddress } from 'ethers/lib/utils'
import produce from 'immer'
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react'
import { AddressInfo } from '../types'

interface Props {
  addressInfo: AddressInfo
  setAddressList: Dispatch<SetStateAction<Array<AddressInfo>>>
  index: number
}

function isValidAddress(account: string) {
  return account.length === 42 && isAddress(account)
}

const AccountRow = ({ addressInfo, setAddressList, index }: Props) => {
  const { address, id, amount } = addressInfo
  const [error, setError] = useState(!isValidAddress(address))

  // useEffect(() => {
  //   document.getElementById(`standard-number-${index}`)?.addEventListener('keydown', (e) => e.preventDefault())
  // }, [])

  const validAmount = () => amount > 0 && amount <= 200

  useEffect(() => {
    setError(!isValidAddress(address))
  }, [address])

  // TODO: https://web3-tools.netlify.app/ toChecksumAddress
  const onChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    const account = event.currentTarget.value
    const isValid = isValidAddress(account)
    setError(!isValid)
    setAddressList(
      produce((draft) => {
        const target = draft.find((it: any) => it.id === id)
        // @ts-ignore
        target.address = account
      })
    )
  }
  const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/

    setAddressList(
      produce((draft) => {
        const target = draft.find((it: any) => it.id === id)
        if (e.target.value === '' || regex.test(e.target.value)) {
          // @ts-ignore
          target.amount = +e.target.value
        }
      })
    )
  }

  const onClickDeleteRow = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    if (document.querySelectorAll('.account-address input').length === 1) {
      return
    }
    setAddressList(
      produce((draft) => {
        return draft.filter((it: any) => it.id !== id)
      })
    )
  }

  return (
    <Box sx={{ display: 'flex' }} className={`account-row ${error ? `error` : ''}`}>
      <Box
        sx={{
          width: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '10px',
          marginRight: '10px'
        }}
      >
        <strong>{index + 1}</strong>
      </Box>
      <TextField
        required={true}
        id="standard-required"
        label="Wallet Address"
        variant="standard"
        onChange={onChangeAddress}
        sx={{ m: 1, width: '500px' }}
        error={error}
        helperText={error ? 'not valid account address' : ''}
        className={'account-address'}
        value={address}
      />
      <TextField
        id={`standard-number-${index}`}
        label="수량"
        InputLabelProps={{
          shrink: true
        }}
        sx={{ m: 1 }}
        variant="standard"
        className={'amount'}
        error={!validAmount()}
        helperText={!validAmount() ? 'not valid amount' : ''}
        value={amount}
        onChange={onChangeAmount}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button variant="contained" onClick={onClickDeleteRow} sx={{ height: '30px' }} color="error">
          REMOVE
        </Button>
      </Box>
    </Box>
  )
}

export default AccountRow
