import { API_URL } from 'const'
import { useQuery } from 'react-query'

export type InviteCode = {
  code: string
  createat: string
  owner: string
  used: boolean | null
  user: string | null
  isHolder: boolean
}

export function useAdminInviteCodesList() {
  const inviteCodes = useQuery<InviteCode[]>(['inviteCodes', 'admin'], () =>
    fetch(`${API_URL}/api/invitecodes/admin/list`, {
      headers: {
        'Content-Type': 'application/json;chaset=utf-8'
      },
      credentials: 'include'
    }).then(async (res) => res.json())
  )
  return inviteCodes
}
