import ntxABI from './ntx.abi.json'

export const NTX_CONFIG = {
  hardhat: {
    addressOrName: '0x5fbdb2315678afecb367f032d93f642f64180aa3', // packages/contract (Avatar -> NTX 수정 후) yarn dev -> 터미널에 찍히는 주소 사용
    contractInterface: ntxABI,
    deployBlock: 0
  },
  mumbai: {
    addressOrName: '0x1C07cC58fca66fa21a0f5c7217Ef83234Ada02d3',
    contractInterface: ntxABI,
    deployBlock: 33455599
  },
  polygon: {
    addressOrName: '0x8Fc9585896682c855F502335A5f39D584FfC4386',
    contractInterface: ntxABI,
    deployBlock: 35629975
  }
}

export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL || 'http://localhost:3000'
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080'
export const API_GAME_URL = process.env.REACT_APP_API_GAME_URL || 'http://localhost:8000'

export const HARDHAT_CHAIN_ID = 31337
export const MUMBAI_CHAIN_ID = 80001
export const POLYGON_CHAIN_ID = 137

export const DEFAULT_ADMIN_ROLE = '0x0000000000000000000000000000000000000000000000000000000000000000'
export const MINT_ROLE = '0x154c00819833dac601ee5ddded6fda79d9d8b506b911b3dbd54cdb95fe6c3686'
export const BURN_ROLE = '0xe97b137254058bd94f28d2f3eb79e2d34074ffb488d042e3bc958e0a57d2fa22'
