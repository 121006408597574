import { AppBar, Logout, TitlePortal, UserMenu } from 'react-admin'
import { WalletLogin } from '../components/WalletLogin'

const CustomUserMenu = () => (
  <UserMenu>
    <Logout />
  </UserMenu>
)

const CustomAppBar = (props: any) => (
  <AppBar {...props} color="secondary" elevation={1} userMenu={<CustomUserMenu />}>
    <TitlePortal />
    <WalletLogin />
  </AppBar>
)

export default CustomAppBar
