import InviteCodeCreatePage from 'pages/InviteCodeCreatePage'
import InviteCodesPage from 'pages/InviteCodesPage'
import InviteCodeUnregisterPage from 'pages/InviteCodeUnregisterPage'
import jsonServerProvider from 'ra-data-json-server'
import { Admin, CustomRoutes, Resource } from 'react-admin'
import { Route } from 'react-router-dom'
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import authProvider from './authProvider'
import Layout from './layout/Layout'
import LoginPage from './pages/LoginPage'
import NTxAccountPage from './pages/NTxAccountPage'
import NTxAirdropPage from './pages/NTxAirdropPage'
import NTxBurnPage from './pages/NTxBurnPage'
import NTxEventPage from './pages/NTxEventPage'
import NTxMintlist from './pages/NTxMintlistPage'

const { chains, provider, webSocketProvider } = configureChains(
  [chain.mainnet, chain.hardhat, chain.polygon, chain.polygonMumbai],
  [alchemyProvider({ apiKey: 'jyy_qgH6yU_RVKRsO2HxV97XI_BF9V8h' }), publicProvider()]
)
const client = createClient({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  provider,
  webSocketProvider
})
const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com')

export default function App() {
  return (
    <WagmiConfig client={client}>
      <Admin
        loginPage={LoginPage}
        dataProvider={dataProvider}
        layout={Layout}
        dashboard={Dashboard}
        authProvider={authProvider}
        requireAuth
      >
        <Resource name="ntx" list={NTxAirdropPage} />
        <CustomRoutes>
          <Route path="/ntx-airdrop" element={<NTxAirdropPage />} />
          <Route path="/ntx-burn" element={<NTxBurnPage />} />
          <Route path="/ntx-account" element={<NTxAccountPage />} />
          <Route path="/ntx-event" element={<NTxEventPage />} />
          <Route path="/ntx-mintlist" element={<NTxMintlist />} />
        </CustomRoutes>
        <Resource name="holder-test" list={InviteCodesPage} />
        <CustomRoutes>
          <Route path="/invite-codes" element={<InviteCodesPage />} />
          <Route path="/invite-code-create" element={<InviteCodeCreatePage />} />
          <Route path="/invite-code-unregister" element={<InviteCodeUnregisterPage />} />
        </CustomRoutes>
      </Admin>
    </WagmiConfig>
  )
}

const Dashboard = () => {
  return <div>Dashboard</div>
}
