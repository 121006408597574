import { useEffect, useState } from 'react'
import { useNetwork } from 'wagmi'

import { MUMBAI_CHAIN_ID, POLYGON_CHAIN_ID } from '../const'

export function useExplorerPrefix(): string {
  const [explorerPrefix, setExplorerPrefix] = useState('')

  const { chain } = useNetwork()

  useEffect(() => {
    const chainId = chain?.id

    if (chainId) {
      if (chainId === POLYGON_CHAIN_ID) {
        setExplorerPrefix('https://polygonscan.com/')
      } else if (chainId === MUMBAI_CHAIN_ID) {
        setExplorerPrefix('https://mumbai.polygonscan.com/')
      }
    }
  }, [chain])

  return explorerPrefix
}
