import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { ethers } from 'ethers'
import { isAddress } from 'ethers/lib/utils'
import _ from 'lodash'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useSigner } from 'wagmi'
import { useExplorerPrefix } from '../hooks/useExplorerPrefix'
import { AddressInfo } from '../types'
import AccountRow from './AccountRow'

const initialAccountInfo: AddressInfo = {
  address: '',
  amount: 1,
  id: 1
}

const maxRow = 2000
const maxNum = 200
const maxRowPerTransaction = 300

interface Transaction {
  index: number
  hash: string
  numAccount: number
  isMined: boolean
  status: boolean
}

interface TransactionDialogProps {
  txIndex: number
  open: boolean
  transactions: Transaction[]
  totalNumAccount: number
}

function TransactionDialog(props: TransactionDialogProps) {
  const { open, transactions, txIndex } = props
  const explorerPrefix = useExplorerPrefix()

  const getTransactionStatus = (transaction: Transaction) => {
    if (transaction && transaction.hash) {
      return transaction.isMined ? (transaction.status ? 'Mined ✅' : 'Reverted ❌') : 'Pending ⌛'
    }

    return `Waiting for signing 🔏 ${transaction.index === txIndex ? '👈' : ''} `
  }

  return (
    <Dialog open={open}>
      <Box sx={{ justifyContent: 'center' }}>
        <Box sx={{ paddingLeft: '16px' }}>
          <h2>Transaction</h2>
        </Box>
        {transactions.map(
          (transaction: Transaction, index) =>
            transaction && (
              <Box key={index} sx={{ width: '800px', padding: '16px' }}>
                <div>
                  Airdrop NTx ({transaction.numAccount}/{props.totalNumAccount}) - {getTransactionStatus(transaction)}
                </div>
                <span>txHash: (</span>
                <a href={explorerPrefix + 'tx/' + transaction.hash} target="_blank" rel="noopener noreferrer">
                  {transaction.hash}
                </a>
                <span>)</span>
              </Box>
            )
        )}
      </Box>
    </Dialog>
  )
}

export function Airdrop({ contract, setIsMined, isMinter }: any) {
  const count = useRef(1)
  const [isMintLoading, setIsMintLoading] = useState(false)
  const [accountInfoList, setAccountInfoList] = useState<Array<AddressInfo>>([{ ...initialAccountInfo }])
  const [duplicatedAddressList, setDuplicatedAddressList] = useState<Array<string>>([])
  const [totalNumAccount, setTotalNumAccount] = useState(0)
  const { data: signer } = useSigner()
  const [txs, setTxs] = useState<Array<Transaction>>([])
  const [txIndex, setTxIndex] = useState(0)

  let transactions: Transaction[] = []

  useEffect(() => {
    const accountList = accountInfoList.map((addressInfo) => addressInfo.address.toLowerCase())

    const findDuplicateList = (arr: string[]) => arr.filter((item, index) => arr.indexOf(item) !== index)
    setDuplicatedAddressList(Array.from(new Set(findDuplicateList(accountList))))
  }, [accountInfoList])

  const updateTransaction = (index: number, transactionHash: string, isMined: boolean, status: boolean) => {
    const newTransactions = transactions.map((transaction: Transaction) => {
      if (transaction.index === index) {
        // pending
        if (transactionHash) {
          return { ...transaction, hash: transactionHash }
        }

        // mined
        if (!transactionHash || isMined) {
          return { ...transaction, isMined: true, status: status }
        }
      }

      return transaction
    })

    transactions = newTransactions
    setTxs(transactions)
  }

  const onMintClicked = async () => {
    if (!isMinter) return alert("You're NOT minter! 🚨")

    const accounts = Array.from(document.querySelectorAll('.account-address input')).map((el: any) => el.value)
    const amounts = Array.from(document.querySelectorAll('.amount input')).map((el: any) => el.value)

    if (amounts.findIndex((amount) => amount === '0') !== -1) {
      return alert('수량이 0인 행이 존재합니다.')
    }
    if (amounts.findIndex((amount) => parseInt(amount) > maxNum) !== -1) {
      return alert(`수량이 ${maxNum}개가 넘는 행이 존재합니다.`)
    }

    accounts.forEach((acc) => {
      if (!isAddress(acc)) return alert('PLEASE CHECK ACCOUNTS')
    })

    const getAccountsNotToBeAirdropped = (accountsToBeAirdropped: any[]) =>
      accountInfoList.filter((accountInfo) => !accountsToBeAirdropped.includes(accountInfo.address))

    try {
      setIsMintLoading(true)
      setTotalNumAccount(amounts.length)

      const totalAccounts = accounts.length
      const txNum =
        totalAccounts % maxRowPerTransaction === 0
          ? Math.floor(totalAccounts / maxRowPerTransaction)
          : Math.floor(totalAccounts / maxRowPerTransaction) + 1

      for (let i = 0; i < txNum; i++) {
        const from = i * maxRowPerTransaction
        const to = (i + 1) * maxRowPerTransaction

        const newTransaction = {
          index: i,
          hash: '',
          numAccount: accounts.slice(from, to).length,
          isMined: false
        } as Transaction

        transactions.push(newTransaction)
      }
      setTxs(transactions)

      let accountsToBeAirdropped: any[] = []

      for (const index in new Array(txNum).fill('')) {
        const i = parseInt(index) // index is type of string
        setTxIndex(i)

        const from = i * maxRowPerTransaction
        const to = (i + 1) * maxRowPerTransaction

        const estimatedGas = (
          await contract.connect(signer)?.estimateGas.airdrop(accounts.slice(from, to), 0, amounts.slice(from, to))
        ).toNumber()

        let tx
        try {
          tx = await contract.connect(signer)?.airdrop(accounts.slice(from, to), 0, amounts.slice(from, to), {
            gasLimit: Math.round(estimatedGas * 1.2)
          })
        } catch (err: any) {
          if (i < txNum - 1 && !window.confirm('Do you want to send the next transaction?')) {
            const accountsNotToBeAirdropped = getAccountsNotToBeAirdropped(accountsToBeAirdropped)
            setAccountInfoList(accountsNotToBeAirdropped)
            count.current = accountsNotToBeAirdropped.length

            alert(`airdrop ${accountsToBeAirdropped.length} success, ${accountsNotToBeAirdropped.length} failed`)
            setIsMined((prev: number) => prev + 1)
            setIsMintLoading(false)
            setTotalNumAccount(0)

            return
          }

          continue
        }

        if (tx.hash) {
          updateTransaction(i, tx.hash, false, false)
        }

        const receipt = await tx.wait()
        if (receipt) {
          if (receipt.status) accountsToBeAirdropped = accountsToBeAirdropped.concat(accounts.slice(from, to))
          updateTransaction(i, '', true, receipt.status)
        }
      }

      const accountsNotToBeAirdropped = getAccountsNotToBeAirdropped(accountsToBeAirdropped)
      setAccountInfoList(accountsNotToBeAirdropped)
      count.current = accountsNotToBeAirdropped.length

      alert(`airdrop ${accountsToBeAirdropped.length} success, ${accountsNotToBeAirdropped.length} failed`)
      setIsMined((prev: number) => prev + 1)
      setIsMintLoading(false)
      setTotalNumAccount(0)
    } catch (err: any) {
      setIsMintLoading(false)
      console.log(err.trace)
    }
  }

  const onPlusClicked = () => {
    if (accountInfoList.length === maxRow) return alert(`🚨 행의 개수는 최대 ${maxRow}개 입니다. 🚨`)

    let canAddRow = true

    accountInfoList.forEach((addressInfo: AddressInfo) => {
      if (addressInfo.address === '') {
        return (canAddRow = false)
      }
    })
    if (!canAddRow) return alert('아직 채워지지 않은 행이 존재합니다.')

    count.current += 1
    setAccountInfoList((prev) => prev.concat([{ ...initialAccountInfo, ...{ id: count.current, no: count.current } }]))
  }

  const onRemoveAllInvalidAccountsClick = () => {
    const [validAccountInfo, invalidAccountInfo] = _.partition(accountInfoList, (accountInfo: any) => {
      try {
        ethers.utils.getAddress(accountInfo.address) // if address is invalid, error is thrown.
        return true
      } catch (err: any) {
        return false
      }
    })

    if (accountInfoList.length === 1) return
    if (invalidAccountInfo.length === 1 && invalidAccountInfo[0].address === '') return
    if (invalidAccountInfo.length === 0) return alert('🚨 THERE ARE NO INVALID ACCOUNTS 🚨')

    let addressListToBeRemoved = ''
    for (let i = 0; i < invalidAccountInfo.length; i++) {
      addressListToBeRemoved = addressListToBeRemoved.concat(`${invalidAccountInfo[i].address}\n`)
    }
    const result = window.confirm(`Are you sure you want to remove all these accounts?\n
${addressListToBeRemoved}
    `)

    if (result) {
      setAccountInfoList(validAccountInfo)
      count.current -= invalidAccountInfo.length
    }
  }

  const validate = () => {
    const els = document.getElementsByClassName('account-row')
    const errorEl = Array.from(els).find((el) => el.classList.contains('error'))
    return !errorEl
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    if (validate()) {
      // proceed to submit
      onMintClicked().catch((e) => {
        console.error('error occurred while minting', e)
      })
    } else {
      alert('유효하지 않은 account address 가 존재합니다.')
    }
  }

  const importExcel = (event: ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    const input = event.target
    const reader = new FileReader()

    reader.onload = function () {
      const fileData = reader.result
      //@ts-ignore
      const wb = XLSX.read(fileData, { type: 'binary' })

      const sheetName = wb.SheetNames[0]
      //@ts-ignore
      const sheetJson = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
      const parsedSheetJson = JSON.parse(JSON.stringify(sheetJson))

      if (
        parsedSheetJson.length === 0 ||
        !parsedSheetJson[0].hasOwnProperty('no') ||
        !parsedSheetJson[0].hasOwnProperty('address') ||
        !parsedSheetJson[0].hasOwnProperty('amount')
      ) {
        return alert('규격에 맞지 않은 파일입니다.')
      }
      if (parsedSheetJson.length > maxRow) {
        return alert(`최대 행 갯수(${maxRow}) 초과 입니다.`)
      }

      const accountInfoList = parsedSheetJson.map((obj: any, i: number) => {
        if (!obj.amount) obj.amount = 0

        obj.id = i + 1
        return obj
      })

      const [validAccountInfo, invalidAccountInfo] = _.partition(accountInfoList, (accountInfo: any) => {
        try {
          ethers.utils.getAddress(accountInfo.address) // if address is invalid, throw error

          return true
        } catch (err: any) {
          if (err) {
            console.log(`catch invalid address: ${accountInfo.address}, error: ${err.message}`)
          }
          return false
        }
      })

      setAccountInfoList(invalidAccountInfo.concat(validAccountInfo))
      count.current = accountInfoList.length
    }
    //@ts-ignore
    reader.readAsBinaryString(input.files[0])
  }

  return (
    <div>
      <h3>AIRDROP NTx 🚀</h3>
      <input type="file" accept={'.xls,.xlsx'} onChange={(e) => importExcel(e)} />

      <Button variant="contained" onClick={onPlusClicked} sx={{ marginRight: '10px' }}>
        ADD
      </Button>
      <Button variant="contained" onClick={onRemoveAllInvalidAccountsClick} color="error">
        REMOVE (All Invalid Accounts)
      </Button>

      <form onSubmit={onSubmit}>
        {accountInfoList.map((addressInfo, index) => (
          <AccountRow key={index} addressInfo={addressInfo} index={index} setAddressList={setAccountInfoList} />
        ))}

        {duplicatedAddressList.length !== 0 && (
          <Alert severity="error" sx={{ m: 1, width: '600px', margin: '5px', marginBottom: '20px' }}>
            <AlertTitle>중복되는 어카운트가 존재합니다.</AlertTitle>
            {duplicatedAddressList.map((address) => (
              <div>
                <strong>{address}</strong>
              </div>
            ))}
          </Alert>
        )}
        <Button
          sx={{ marginTop: '20px', marginBottom: '100px' }}
          variant="contained"
          type="submit"
          onClick={onSubmit}
          disabled={isMintLoading || duplicatedAddressList.length > 0}
        >
          {isMintLoading ? <span>AIRDROPPing...</span> : <span>AIRDROP</span>}
        </Button>
      </form>
      {isMintLoading && (
        <TransactionDialog open={true} transactions={txs} totalNumAccount={totalNumAccount} txIndex={txIndex} />
      )}
    </div>
  )
}
