import { Pagination } from '@mui/material'
import { API_URL } from 'const'
import { useEffect, useState } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import * as XLSX from 'xlsx'
import { useExplorerPrefix } from '../hooks/useExplorerPrefix'
import usePagination from '../hooks/usePagination'

export default function NTxAccountPage() {
  const { isConnected } = useAccount()
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const { chain } = useNetwork()
  const PER_PAGE = 500
  const explorerPrefix = useExplorerPrefix()

  const count = Math.ceil(data.length / PER_PAGE)
  const _DATA = usePagination(data, PER_PAGE)

  console.log(chain)

  useEffect(() => {
    fetch(`${API_URL}/api/ntx/admin/accounts?network=${chain?.network}`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((err: any) => console.log(err.message))
  }, [])

  const handleChange = (e: any, p: any) => {
    setPage(p)
    _DATA.jump(p)
  }

  const _download = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()

    worksheet['!cols'] = [{ wch: 6 }, { wch: 44 }, { wch: 6 }, { wch: 20 }, { wch: 200 }, { wch: 400 }]

    XLSX.utils.book_append_sheet(workbook, worksheet, '1')
    XLSX.writeFile(workbook, 'ntx_holders.xlsx')
  }

  const downloadThisPage = () => {
    if (data.length === 0) return alert('export할 데이터가 없습니다.')

    _download(_DATA.currentData())
  }

  const downloadAll = () => {
    if (data.length === 0) return alert('export할 데이터가 없습니다.')

    _download(data)
  }

  return isConnected ? (
    <div>
      <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />

      <div>
        <button
          style={{ marginTop: '20px', marginRight: '10px' }}
          onClick={() => {
            downloadThisPage()
          }}
        >
          EXPORT(xlsx) - accounts (only this page)
        </button>
        <button
          style={{ marginTop: '20px' }}
          onClick={() => {
            downloadAll()
          }}
        >
          EXPORT(xlsx) - accounts (all)
        </button>
      </div>

      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
        <div style={{ width: '100px' }}>ranking</div>
        <div style={{ width: '400px' }}>account</div>
        <div style={{ width: '100px' }}>amount</div>
        <div style={{ width: '400px' }}>history</div>
      </div>
      <hr />
      <div>
        {_DATA &&
          _DATA.currentData().map((data: any, index: number) => {
            const timestamp = data.timestamp.split(',')
            const value = data.value.split(',')
            const transactionHash = data.transactionHash.split(',')

            const loop = Array.from(Array(timestamp.length).keys())

            return (
              <div key={index} style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '18px' }}>
                <div style={{ width: '100px' }}>{data.ranking}</div>
                <div style={{ width: '400px' }}>{data.address}</div>
                <div style={{ width: '100px' }}>{data.amount}</div>
                <div style={{ width: '400px' }}>
                  {loop.map((index: any) => {
                    return (
                      <div key={index} style={{ flex: 0.5 }}>
                        <a
                          href={explorerPrefix + 'tx/' + transactionHash[index]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          - {value[index] >= 0 ? 'mint' : 'burn'} {value[index]} ({timestamp[index]}+UTC)
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  ) : (
    <div>Please Connect Wallet</div>
  )
}
